/*
  maz - HI dashboard date time formatter
  usage: this.$dt.format("2020-09-28T20:51:15+00:00")
*/
import Vue from "vue";

export default {

  install(Vue,) {
    Object.defineProperty(Vue.prototype, '$dt', { value: this });    
  },

  format(ISODatetime) {
    // user preference
    const usrTimezone = Vue.http.options.config?.dashboard?.timezone || "";
    const usrDTFormat = Vue.http.options.config?.dashboard?.datetimeFormat;
    // system (browser)
    const sysTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const sysDTFormat = "L LTS";    
    try {
      return ISODatetime ? moment(ISODatetime)
        .tz(usrTimezone || sysTimezone)
        .format(usrDTFormat || sysDTFormat) : ISODatetime;
    } catch (e) {
      return ISODatetime ? moment(ISODatetime).format(sysDTFormat) : ISODatetime;
    }
  }
}
import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import ConnectorService from "@/services/connector.js";
import { parseMessage } from "@/utils";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
const simulator = (lst) => {
  window.dev_flag = window.dev_flag ? false : true;
  lst.forEach((item) => {
    item.connected = window.dev_flag;
    item.enabled = window.dev_flag;
  });
};
*/

/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
*/

const baseurl = ""; //v1  set to "" after conversion

const machineAdapter = (machine, lst) => {
  machine.connector_id = parseInt(machine.connector_id); // would not be necessary
  return machine;
};

const machineListAdapter = (payload) => {
  let lst = payload.id ? [payload] : payload.length ? payload : [];
  return (lst || []).map((item) => {
    machineAdapter(item, lst);
    return item;
  });
};

export { machineListAdapter, machineAdapter };

export default class MachineService {
  async fetch(query, URL) {
    return new Promise((resolve) => {
      let url = URL || `${baseurl}machines/?format=json`;
      let auth = new Auth();
      if (query) {
        if (query.filters && typeof query.filters == "object") {
          query = { ...query, ...query.filters };
          delete query.filters;
        }
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          resolve(response?.body?.length ? response.body : []);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}machines/`; //v1
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async get(id, contract_id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}machines/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            let data = response.body;
            let srv = new ConnectorService();
            data.connector = srv.adapter(data.connector);
            resolve(data);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}machines/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
                parseMessage(error.body ?? {}) ||
                "Failed to delete"
            );
          }
        );
    });
  }

}

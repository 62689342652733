import Vue from "vue";
import MachineService from "../services/machine.js";
import ReasonService from "../services/reason.js";
import CasualtyService from "../services/casualty.js";

function initialState() {
  return {
    isReady: false,
    machines: null,
    reasons: null,
    casualties: null
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_MACHINES(state, option) {
      Vue.set(state, "machines", option);
    },
    REMOVE_MACHINE(state, resource) {
      let machines = (state.machines || []).filter(
        (item) => item.id != resource.id
      );
      Vue.set(state, "machines", machines);
    },
    SET_MACHINE(state, resource) {
      let exists = (state.machines || []).find(
        (item) => item.id == resource.id
      ) ? true : false;

      let machines = (state.machines || []);

      if (exists) {
        machines = machines.map((item) => {
          if (item.id == resource.id)
            return resource
          else
            return item
        });
      } else {
        machines.push(resource);
      }
      Vue.set(state, "machines", machines);
    },
    SET_REASONS(state, option) {
      Vue.set(state, "reasons", option);
    },
    SET_CASUALTIES(state, option) {
      Vue.set(state, "casualties", option);
    },
    IS_READY(state, option) {
      state.isReady = option;
    }
  },
  actions: {
    reset({ state, commit, rootState }) {
      commit("RESET");
    },
    removeMachine(context, resource) {
      context.commit("REMOVE_MACHINE", resource);
    },
    setMachine(context, resource) {
      context.commit("SET_MACHINE", resource);
    },
    fetchMachines({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        if (
          !(
            rootState.user &&
            rootState.user.loggedUser &&
            "contract_id" in rootState.user.loggedUser
          ) ||
          state.machines instanceof Array
        )
          return;
        let query = {
          contract_id: rootState.user.loggedUser.contract_id
        };

        commit("IS_READY", false);
        let srv = new MachineService();
        srv.fetch(query).then((data) => {
          if (data && data.length) {
            commit("SET_MACHINES", data);
          }
          commit("IS_READY", true);
          resolve(data);
        });
      });
    },
    fetchCasualties({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        if (
          !(
            rootState.user &&
            rootState.user.loggedUser &&
            "contract_id" in rootState.user.loggedUser
          ) ||
          state.casualties instanceof Array
        )
          return;
        let query = {
          contract_id: rootState.user.loggedUser.contract_id
        };
        commit("IS_READY", false);
        let srv = new CasualtyService();
        return srv.fetch(query).then((data) => {
          if (data && data.length) {
            commit("SET_CASUALTIES", data);
          }
          commit("IS_READY", true);
          resolve(data);
        });
      });
    },
    resetCasualties({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        commit("SET_CASUALTIES", null);
        return resolve();
      });
    },
    fetchReasons({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        if (
          !(
            rootState.user &&
            rootState.user.loggedUser &&
            "contract_id" in rootState.user.loggedUser
          ) ||
          state.reasons instanceof Array
        )
          return;
        let query = {
          contract_id: rootState.user.loggedUser.contract_id
        };
        commit("IS_READY", false);
        let srv = new ReasonService();
        return srv.fetch(query).then((data) => {
          if (data && data.length) {
            commit("SET_REASONS", data);
          }
          commit("IS_READY", true);
          resolve(data);
        });
      });
    },
    resetReasons({ state, commit, rootState }) {
      return new Promise((resolve, reject) => {
        commit("SET_REASONS", null);
        return resolve();
      });
    },
    saveReasons(context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) return;
        let srv = new ReasonService();
        context.commit("IS_READY", false);
        srv.save(payload).then(
          (data) => {
            context.commit("SET_REASONS", data);
            context.commit("IS_READY", true);
            resolve(data)
          },
          (err) => {
            context.commit("IS_READY", true);
            reject(err)
          }
        );
      })
    },
    saveCasualties(context, payload) {
      return new Promise((resolve, reject) => {
        if (!payload) return;
        let srv = new CasualtyService();
        context.commit("IS_READY", false);
        srv.save(payload).then(
          (data) => {
            context.commit("SET_CASUALTIES", data);
            context.commit("IS_READY", true);
            resolve(data)
          },
          (err) => {
            context.commit("IS_READY", true);
            reject(err)
          }
        );
      })
    }
  },
  getters: {
    isReady(state) {
      return state.isReady;
    },
    reasons(state) {
      return state.reasons ?? [];
    },
    casualties(state) {
      return state.casualties ?? [];
    },
    machines(state) {
      return state.machines ?? [];
    }
  }
};

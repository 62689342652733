import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { parseMessage } from "@/utils";

Vue.use(VueResource);

/*
ref task:
https://hitecnologia.bitrix24.com.br/extranet/workgroups/group/23/tasks/task/view/14839/?MID=16161#com16161

PortalPermission (cadastradas pela HI e associadas aos recursos do Portal)
   - identity_name: models.CharField(), nome associado a permissão, unique e not null
   - description: models.CharField(), descrição associado a permissão
   - identity_value: models.Integer(), valor associado a permissão, unique e not null
   * unique: name
   
ContractPermission (cadastradas pelo usuario). Este modelo prepara para os recursos que o Marco sentiu falta
   - identity_name: models.CharField(), nome associado a permissão, not null
   - description: models.CharField(), descrição associado a permissão
   - contract: models.ForeignKey(Contrato), not null
   - identity_value: models.Integer(), valor associado a permissão, unique e not null
   * unique: name e contract
   * unique: value e contract
    
ContractUserGroups (cadastradas pela HI para o contexto da Messer e pelo usuário futuramente)
   - identity_name: models.CharField(), nome associado ao grupo e not null
   - description: descrição associado ao grupo          
   - contract: models.ForeignKey(Contrato), not null
   - portal_permissions: models.ManyToManyField(PortalPermission)
   - contract_permissions: models.ManyToManyField(ContractPermission)
   * unique: name e contract
   ** usuario deve ser membro do contrato para ser incluido aqui!!!   
   
ContractProcessArea (cadastradas pela aplicação da Messer)
   - identity_name: nome associado a area de processo
   - description: descrição associado a area de processo          
   - contract: models.ForeignKey(Contrato)
   * unique: name e contract

UserGroupsPerProcessArea (cadastradas pela aplicação da Messer).
   - user: models.ForeignKey(User), not null
   - process_area : models.ForeignKey(ContractProcessArea ), not null
   - user_groups: models.ForeignKey(ContractUserGroups ), not null
   * manager()
     - método verifica_permissao_usuario(usuario, area, permissao)
*/

const baseurl = ""; //v1  set to "" after conversion

export default class UserService {
  elapsed(di, info) {
    if (!di) {
      return new Date().getTime();
    } else {
      //console.log(((new Date()).getTime()-di)+"ms "+ (info||''));
    }
  }

  /*
    
    It makes the v1 user interface compatible with v0
    - it will only make use of process areas that belongs to the selected contract
    - the active contract will be defined in user_profile property.
   */
  userAdapter(user, contract_id) {
    user = user || {};
    let contract = (user.user_profile && user.user_profile.contract) || null;
    let checkid = contract_id || (contract && contract.id) || "";
    if (checkid) {
      user.contract_id = checkid;
      user.process_area = [];
      for (var i in user.allowed_permissions) {
        if (user.allowed_permissions[i].contract_id == user.contract_id) {
          user.process_area = user.allowed_permissions[i].process_areas;
          for (let i in user.process_area) {
            user.process_area[i].identity_name =
              user.process_area[i].name || "";
            for (let j in user.process_area[i].user_groups) {
              user.process_area[i].user_groups[j].identity_name =
                user.process_area[i].user_groups[j].name || "";
            }
          }
          break;
        }
      }
    }

    return user;
  }

  async fetch(query) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/users/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            var data = response.body;
            for (var i in data) {
              data[i] = this.userAdapter(
                data[i],
                ("contract_id" in query && query.contract_id) || ""
              );
            }
            resolve(data);
            return;
          }
          resolve(null);
        },
        (error) => {
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve, reject) => {
      let url = `${baseurl}contracts/${payload.contract_id}/member_users/`;
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(this.userAdapter(response.body, payload.contract_id));
            return;
          }
          resolve(null);
        },
        ({ body = {} }) => reject(body.detail ?? parseMessage(body))
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}contracts/${payload.contract_id}/member_users/${payload.id}/?format=json`;
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(error.body?.detail);
          }
        );
    });
  }

  async patch(user_id, payload) {
    return new Promise((resolve, reject) => {
      let url = `${baseurl}auth/users/${user_id}/`;
      let auth = new Auth();
      let options = auth.requestOptions(
        payload.etag ? { "If-Match": payload.etag } : null
      );
      let request = Vue.http.patch(url, payload, options);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        ({ body = {} }) => reject(body.detail ?? parseMessage(body))
      );
    });
  }

  parseUsers(users) {
    // TODO: the selected contract index must be set at login validation
    let config = Vue.http.options.config;
    return users.map(function(user) {
      // TODO: Since user might be member of another contract, only the selected one will be
      // taken in account
      var selected_contract_index = -1;
      user.user_contracts_areas = user.user_contracts_areas || [];
      if (!user.user_contracts_areas.length && config.contract_id) {
        user.user_contracts_areas.push({
          contract_id: config.contract_id,
          process_areas: []
        });
      }
      if (user.user_contracts_areas.length) {
        if (config.contract_id) {
          for (var i in user.user_contracts_areas) {
            if (
              user.user_contracts_areas[i].contract_id == config.contract_id
            ) {
              selected_contract_index = i;
              break;
            }
          }
        } else {
          selected_contract_index = 0;
        }
      }
      // from now on, a single contract (the selected one) will be treated at user level
      user.contract_id =
        selected_contract_index >= 0
          ? user.user_contracts_areas[selected_contract_index].contract_id
          : config.contract_id;
      user.process_area =
        selected_contract_index >= 0
          ? user.user_contracts_areas[selected_contract_index].process_areas
          : [];
      return user;
    });
  }

  async fetchUsers(query) {
    let self = this;
    let config = Vue.http.options.config;
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = `contratos/${query.contract_id}/membros/`;
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (
            response &&
            response.body &&
            response.body.length &&
            "member_users" in response.body[0]
          ) {
            let users = self.parseUsers(response.body[0].member_users);
            ////console.log(users);
            resolve(users);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async updateProfile(payload) {
    return new Promise((resolve) => {
      let url = `auth/users/`;
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        resolve(null);
        return;
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }
}

import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);

const baseurl = ""; //v1  set to "" after conversion

export default class TextListService {
  async fetch(query) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}text_lists/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}text_lists/`; //v1
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async duplicate(id, payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}text_lists/${id}/duplicate/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(
        url,
        payload,
        auth.requestOptions(payload.etag ? { "If-Match": payload.etag } : null)
      );
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async get(id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}text_lists/${id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}text_lists/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }
}

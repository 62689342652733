import { stats } from "@/modules/history.js";

const _trim = (s, chr) => {
  if (s && typeof s == "string") {
    return s
      .replace(/(\s+|\r?\n|\r|\t)/g, chr || " ")
      .replace(/^\s+|\s+$/g, "")
      ;
  }
  return s;
};

const $valueCast = (t, v) => {
  if (!t || (v === '' || v === undefined || v === null)) return "";
  if (/(^\[|\]$)/.test(_trim(v))) return v;
  // if (/(^\[|\]$)/.test(_trim(v)))
  //   v = _trim(`${v}`.replace(/(^\[|\]$)/g, "").split(",")[0]);
  switch (t) {
    case "int":
    case "bool":
      return parseInt(typeof v === 'string' ? _trim(v) : v);
    case "float":
      return parseFloat(typeof v === 'string' ? _trim(v) : v);
    case "string":
      return _trim(v);
    default:
      return v;
  }
};

const $rawValue = (entry, ix) => {
  let vlr =
    (entry?.current_value &&
      entry.current_value.value != null &&
      entry.current_value.value !== ""
      ? entry.current_value.value
      : "") ?? "";
  if (ix !== undefined && !isNaN(parseInt(ix))) {
    let lst = `${vlr}`.replace(/(^\[|\]$)/g, "").split(",");
    vlr = ix >= 0 && ix < lst.length ? lst[ix] : ""; // out of range;
  }

  return entry?.type
    ? $valueCast(entry.type, vlr)
    : typeof vlr === "string"
      ? _trim(vlr)
      : vlr;
};

const formatter = (vm) => {
  const $vm = vm;
  const $utils = vm.$utils;
  const $config = vm.$root.config;
  const $getters = vm.$store.getters;
  const zeroAsDash = false;

  let $global = {
    $stats: stats
  };

  let data = {
    connectorId: "",
    library: null
  };

  const memoryType = (entry) => {
    return (
      (entry?.memory_type?.id &&
        ($config?.references?.data_memory_types || []).find(
          ({ id }) => parseInt(id) == parseInt(entry?.memory_type?.id)
        )) ||
      null
    );
  };

  const memorySize = (entry) => {
    return entry?.memory_size || 1;
  };

  const dataValueIndex = (entry) => {
    return entry?.portal_data?.data_value_index || null;
  };

  const dataValueCurrentIndex = (entry) => {
    let dataIndex = dataValueIndex(entry);
    if (!(memorySize(entry) > 1) || !dataIndex) return -1;
    let vlr = -1;
    if (dataIndex.type == "constant") {
      vlr =
        dataIndex.value === ""
          ? -1
          : dataIndex.value ?? -1;
    } else if (dataIndex.data_id) {
      let data = $getters["dashboard/dataList"].find(
        ({ id }) => parseInt(id) == parseInt(dataIndex.data_id)
      );
      vlr = parseInt(dataIndex?.offset === "" ? 0 : dataIndex?.offset ?? 0);
      if (data && data.type !== "string") {
        const intValue = parseInt(data?.current_value?.value ?? 0);
        if (isNaN(vlr)) {
          vlr = $utils.evaluate(
            {
              data: {
                current_value: {
                  value: intValue
                }
              },
              $value: intValue,
              value: intValue
            },
            dataIndex?.offset
          );
          vlr = isNaN(parseInt(vlr)) ? intValue : parseInt(vlr);
        } else {
          vlr = vlr + intValue;
        }
      }
    }
    return vlr >= 0 ? vlr : -1;
  };

  const dataFormat = (entry) => {
    return (
      ($config.references.data_value_format_types || []).find(
        (i) => i.id == (entry?.value_format_type?.id || 0)
      ) || null
    );
  };

  const hasDataFormat = (entry) => {
    return (entry.custom_format || (dataFormat(entry) !== null));
  };

  const textListId = (entry) => {
    if (
      (dataFormat(entry)?.format_mask || "") == "text_list" &&
      entry?.text_list
    ) {
      return entry?.text_list?.id || 0;
    }
    return 0;
  };

  const textList = (entry) => {
    if (entry?.text_list?.items) {
      return entry.text_list;
    } else {
      return ($config.references.text_lists || []).find(
        (i) => i.id == textListId(entry)
      );
    }
  };

  const defaultTextListValue = (entry) => {
    var lst = textList(entry);
    if (lst) {
      for (var i in lst.default_item) {
        return i;
      }
    }
    return "";
  };

  const valueCast = $valueCast;

  const rawValue = $rawValue;

  const dataValue = (entry, ix) => {
    var vlr = rawValue(entry, ix);
    var lst = textList(entry);
    if (vlr !== "" && lst && entry?.type != "string") {
      vlr = Math.round(parseFloat(vlr || 0));
      if (!(vlr in lst.items)) {
        vlr = defaultTextListValue(entry);
      }
    }
    return vlr;
  };

  const templateBasedValue = (entry) => {
    /*
      example: 
        input string: "${data?.history?.stats?.minimum||''} | %.2f"
            template: ${data?.history?.stats?.minimum||''}
              format: %.2f

        // querystring {template:"${$qs.hospital}"}
      */
    let template = $utils.trim(entry?.template || "");
    if (template) {
      let result = "";
      let v = template.replace(/\|\|/g, "§").split("|"); // preserve ||
      let expression = v[0].replace(/\§/g, "||");
      let format = v.length > 1 ? v[1] : "";
      const mt = memoryType(entry);
      //
      // it validates if there is a string for format just AFTER last "}"
      // like "${aaa.bbb.ccc} ºC"
      let exp = $utils.trim(expression);
      if (/^\$\{\S+?\}[\b\w\s\S]+$/.test(exp)) {
        expression = exp.match(/^\$\{.+\}/)[0];
        format += exp.substr(expression.length);
      }
      let env = {
        ...{ data: entry || {} },
        ...{ system: $getters.systemProperties }, // legacy screens
        ...{
          value: entry?.current_value?.value ?? "",
          $value: entry?.current_value?.value ?? ""
        },
        $format: (v, fmt) => (typeof fmt !== "string" ? v : $utils.sprintf(fmt, v)),
        $qs: /(\$qs\.|\$qs\[)/.test(expression) ? queryString() : {},
        $at: (i) => rawValue(entry, i), // Usage syntax: $at(2) or $at($('./ccb60b9e/6708d272')?.value)
        $: findResource
      };
      if ($global) {
        for (var key in $global) {
          env[key] = $global[key];
        }
      }
      let evaluated = $utils.evaluate(env, expression);
      if (evaluated !== undefined && evaluated !== "") {
        if (mt?.basic_type?.type == 'string' || format.indexOf("%s") >= 0) {
          result = format === "" ? evaluated : $utils.sprintf(format, evaluated) || evaluated;
        }
        else {
          result = format === "" ? evaluated : evaluated
            .toString()
            .split(" ")
            .map((vlr) => {
              if (vlr === "" || !format) return vlr;
              if (
                !isNaN(vlr) &&
                dataFormat(entry)?.format_mask == "duration" &&
                format.indexOf("%") == -1 &&
                !/Date/.test(expression)
              ) {
                return moment
                  .duration(parseInt(vlr), entry?.unity_label)
                  .format(format, { trim: false });
              } else {
                if (
                  format.indexOf("%") == -1 &&
                  format.match(/(DD|YY|MM|HH|mm|ss)/)
                ) {
                  var dt = new Date(/^[0-9]+$/.test(`${vlr}`) ? parseInt(vlr) : vlr);
                  return dt.toString() == "Invalid Date" ? vlr : moment(dt).format(format);
                } else if (format.indexOf("%") >= 0) {
                  if (isNaN(vlr) || !entry.type || entry.type == 'string') {
                    return $utils.sprintf(format, vlr) || vlr;
                  } else {
                    return $utils.sprintf(format, parseFloat(vlr)) || vlr;
                  }
                } else {
                  return vlr;
                }
              }
            })
            .join(" ");
        }
      }
      return result;
    } else {
      return template;
    }
  };

  const formatedDataValue = (entry, newValue) => {
    let ret = "-";
    if (entry) {
      let vlr = typeof newValue == "undefined" ? dataValue(entry) : newValue;
      const mt = memoryType(entry);
      let lst = textList(entry);
      if (lst) {
        if (vlr in lst.items) {
          ret = lst.items[vlr];
        } else {
          for (var i in lst.default_item) {
            ret = lst.default_item[i];
            break;
          }
        }
        if (ret.indexOf("%") >= 0) {
          vlr = Math.round(parseFloat((entry?.current_value ? rawValue(entry) : vlr) || 0));
          ret = $utils.sprintf(ret, vlr) || ret;
        }
      } else {
        if ("value_format_type" in entry) {
          if (!mt || mt.basic_type.type != "string") {
            if (vlr === "" || (!vlr && zeroAsDash)) {
              ret = "-";
            } else {
              var fmt = dataFormat(entry);
              if (!fmt || fmt.format_mask == "text_list") {
                ret = vlr;
              } else {
                if (fmt.format_mask == "duration") {
                  ret = moment
                    .duration(parseInt(vlr), entry.unity_label)
                    .format(entry.custom_format || undefined, { trim: false });
                } else {
                  ret = $utils.sprintf(
                    entry.custom_format || fmt.format_mask,
                    isNaN(parseFloat(vlr)) ? vlr : parseFloat(vlr)
                  );
                }
              }
            }
          } else {
            if (entry.custom_format) {
              ret = $utils.sprintf(entry.custom_format || fmt.format_mask, vlr);
            } else {
              ret = vlr;
            }
          }
        } else {
          if (vlr != "") {
            ret = vlr;
          }
        }
      }
    }
    return ret;
  };

  const format = (entry) => {
    let vlr = "";
    if (entry.template) {
      vlr = templateBasedValue(entry);
    } else {
      vlr = formatedDataValue(entry);
    }
    return vlr === "" && entry.default ? entry.default : vlr;
  };

  const state = (entry, stateList) => {
    /*
    "stateList": {
      "dataSource": {
        "type": "data",
        "id": 126
      },
      "default": "4",
      "items": [ {
          "state": "0",
          "backgroundColor": "transparent",
          "img": "",
          "label": "zero"
      }...]
     }									
    */
    let _rawValue = typeof (entry) === 'object' ? rawValue(entry) : entry;
    if (_rawValue === '') return null;
    if (stateList && stateList.items && Array.isArray(stateList.items) && stateList.items.length) {
      _rawValue = typeof _rawValue == "boolean"
        ? _rawValue
          ? 1
          : 0
        : _rawValue;
      let defState = stateList?.default ?? "";
      defState = stateList.items.find((i) => defState ?
        i.state == defState :
        String(i.state).toLowerCase() == "default" // legacy
      );
      return (stateList.items.find(
        (i) => String(i.state) !== "" && String(i.state) === String(_rawValue)
      ) || defState || null);
    } else {
      // native on data
      let lst = typeof (entry) === 'object' ? textList(entry) : null;
      if (lst) {
        if (_rawValue in lst.items) {
          return {
            id: _rawValue,
            label: lst.items[_rawValue],
            default: false
          };
        } else {
          for (var i in lst.default_item) {
            return {
              id: i,
              label: lst.default_item[i],
              default: true
            };
          }
        }
      }
    }
    return null;
  };

  const info = (entry) => {
    let info = { type: "", path: "", js: "" };
    if (entry) {
      if (entry?.device && entry?.device?.connector) {
        info.type = "data";
        info.path = `${entry?.device?.connector.id}/${entry?.device
          ?.reference_id || entry?.device?.id}/${entry?.reference_id ||
          entry?.id ||
          "?"}`;
      } else if (entry?.connector) {
        info.type = "device";
        info.path = `${entry?.connector.id ||
          entry?.connector_id}/${entry?.reference_id || entry?.id || "?"}`;
      } else if (entry?.protocol) {
        info.type = "connector";
        info.path = `${entry?.id || "?"}`;
      }
      if (info.path) {
        info.js = `$('${info.path}')?.name`;
      }
    }
    return info;
  };

  const findResource = (path) => {
    // remove trailing slash
    var args = $utils
      .trim(path || "")
      .replace(/\/+$/g, "")
      .split("/");
    const cmp = (id, rid, value) =>
      ($utils.isUUID(value) && rid == value) || parseInt(id) == parseInt(value);
    let connectorId = parseInt(
      args[0] !== "" && args[0] !== "." && args[0] !== "?"
        ? args[0]
        : data.connectorId ||
        $getters["equipmentId"] ||
        $vm?.route?.query?.connectorId ||
        $vm?.route?.query?.connector_id
    );
    if (
      isNaN(connectorId) &&
      $getters["dashboard/mode"] === "editor" &&
      $getters["dashboard/draft"]?.screenId
    ) {
      connectorId =
        (
          $getters["dashboard/screenRefMap"](
            $getters["dashboard/draft"]?.screenId
          ) || { conn1: $getters["dashboard/dashboardEquipmentId"] }
        )?.conn1 || "";
    }
    // important:
    // deviceId might be integer or reference id - therefore can not be casted
    let deviceId =
      args[1] !== undefined &&
        args[1] !== "" &&
        args[1] !== "." &&
        args[1] !== "?"
        ? args[1]
        : data.deviceId ||
        $getters["deviceId"] ||
        $vm?.route?.query?.deviceId ||
        $vm?.route?.query?.device_id;

    if (isNaN(connectorId)) return null;
    switch (args.length) {
      case 1:
        // connector
        return $getters["dashboard/connectorList"].find(
          ({ id }) => parseInt(id) == connectorId
        );
      case 2:
        // device
        return $getters["dashboard/deviceList"].find(
          ({ connector_id, id, reference_id }) => {
            return (
              parseInt(connector_id) == connectorId &&
              cmp(id, reference_id, deviceId)
            );
          }
        );
      case 3:
        // data
        return $getters["dashboard/dataList"].find(
          ({ clp_id, device, id, reference_id }) => {
            return (
              parseInt(clp_id) == connectorId &&
              (
                deviceId && cmp(device.id, device.reference_id, deviceId) &&
                args[2] && cmp(id, reference_id, args[2])
              )
            );
          }
        );
    }
    return null;
  };

  const connectorId = (value) => {
    if (value !== undefined) {
      data.connectorId = value;
    }
    return data.connectorId;
  };

  const maxInputLength = (data) => {
    if (data) {
      const mt = memoryType(data);
      if (mt && mt.basic_type?.type == "string") {
        if (parseInt(data?.string_length) >= parseInt(mt.max_length)) {
          return parseInt(mt.size_in_bytes) * parseInt(mt.writing_max_length);
        } else {
          return (
            parseInt(data?.string_length || mt.max_length) *
            parseInt(mt.size_in_bytes)
          );
        }
      }
    }
    return undefined;
  };

  const library = (name, value) => {
    data.library = data.library || {};
    data.library[name] = null;
    if (value !== undefined) {
      // test
      // data.library["main"]=$utils.buildJSLib(getters["scripts/globalFunctions"])
      const lib = $utils.buildJSLib(value);
      if (Object.keys(lib).length) {
        data.library[name] = lib;
        for (var key in lib) {
          $global = $global || {};
          $global[key] = lib[key];
        }
      }
    }
    return library;
  };

  const queryString = () => {
    let result = { ...($vm.$route.query || {}) };
    if ("_cdim" in $vm.$route.query) {
      result = {
        ...JSON.parse(localStorage.getItem("_cdim") || "{}"),
        ...result
      };
    }
    for (var p in result) {
      if (result[p] && /^\$\(.*$/.test(result[p])) {
        result[p] = templateBasedValue({ template: result[p] });
      }
    }
    return result;
  };

  const callsGlobal = (exp) => {
    return Object.keys($global || {}).map((k) => `${k}(`).some((k) => (exp || "").indexOf(k) >= 0);
  };

  return {
    memoryType,
    memorySize,
    dataValueIndex,
    dataValueCurrentIndex,
    format,
    formatedDataValue,
    templateBasedValue,
    state,
    dataFormat,
    hasDataFormat,
    valueCast,
    rawValue,
    dataValue,
    textList,
    textListId,
    defaultTextListValue,
    info,
    findResource,
    connectorId,
    maxInputLength,
    library,
    callsGlobal
  };
};

export { $rawValue, $valueCast, formatter };

import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { parseMessage } from "@/utils";

Vue.use(VueResource);

const baseurl = ""; //v1  set to "" after conversion
export default class TermsOfServiceService {
  async get(query) {
    let auth = new Auth();
    return new Promise((resolve) => {
      let request;
      let url = `${baseurl}terms_of_service/?format=json`;
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
        request = Vue.http.get(url, auth.requestOptions());
      }
      else {
        request = Vue.http.get(url);
      }
      request.then(
        (response) => {
          if (response && response.bodyText) {
            // response.body.accepted_datetime = null;
            resolve(response.body);
            return
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async save(payload) {
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = `${baseurl}terms_of_service/`;
      let request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }
}

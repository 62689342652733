import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { doRemove, doGet } from "@/services/auth.js";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
*/
const baseurl = ""; //v1  set to "" after conversion

const simulator = (lst) => {
  window.conn_flag = window.conn_flag ? false : true;
  lst.forEach((item) => {
    item.connected = window.conn_flag;
    item.has_active_alarms = window.conn_flag;
    item.enabled = window.conn_flag;
  });
};

const isMQTT = (connector) => {
  let references = Vue?.http?.options?.config?.references || null;
  if (references && connector && connector?.connector_type?.id) {
    let connector_type = (references?.connector_types || []).find(
      ({ id }) => id == connector?.connector_type?.id
    );
    if (connector_type && connector_type?.allowed_protocols?.length) {
      let protocol = (connector_type?.allowed_protocols || []).find(
        ({ id }) => id == connector?.protocol?.id
      );
      return (protocol && protocol?.is_mqtt_protocol) || false;
    }
  }
  return false;
};

const isSingleDevice = (connector) => {
  // add any other protocol that does not allow multiple devices
  return isMQTT(connector) || false;
};

export { isMQTT, isSingleDevice };

export default class ConnectorService {
  // make it compatible with previous version
  adapter(connector) {
    // remove extra dots from name attribute
    connector.name = connector.name.replace(/\./g, "");
    // screen_id
    if (!connector.screen_id) {
      if (Vue.http?.options?.config?.screens?.length) {
        let screen = Vue.http.options.config.screens.find(
          (i) => "default" in i && i.default
        );
        if (!screen) {
          screen = Vue.http.options.config.screens[0];
        }
        connector.screen_id = screen.id;
      }
    }
    // OnlinEE machine
    connector.machine = null;
    return connector;
  }

  async fetch(query, URL) {
    let self = this;
    return new Promise((resolve) => {
      let url = URL || `${baseurl}connectors/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            let lst =
              "only_state" in (query || {})
                ? response.body
                : (response.body || []).map((i) => {
                  return self.adapter(i);
                });
            // begin test
            // if ("only_state" in query || {}) {
            //   simulator(lst);
            // }
            // end test
            resolve(lst);
            return;
          }
          resolve(null);
        },
        (error) => {
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/`; //v1
      let request = null;
      let auth = new Auth();
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(this.adapter(response.body));
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async export(params, fname) {
    let self = this;
    fname = fname || "connectors.csv";
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/export/?${params}`; //v1
      let auth = new Auth();
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            _vue.$utils.download(response.bodyText, "text/csv", fname);
            return;
          }
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async import(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/import/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async duplicate(id, payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/${id}/duplicate/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(
        url,
        payload,
        auth.requestOptions(payload.etag ? { "If-Match": payload.etag } : null)
      );
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(this.adapter(response.body));
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async get(id, contract_id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            //begin test
            // simulation([response.body]);
            //end test
            resolve(self.adapter(response.body));
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    return doRemove(new Auth(), "connectors", payload);
  }

  async removeMultiple(payload) {
    return doRemove(new Auth(), "connectors", payload);
  }

  async getView(id, contract_id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}connectors/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(self.adapter(response.body));
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }
}

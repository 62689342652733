import Vue from "vue";
import RoleService from "@/services/role";

function initialState() {
  return {
    isReady: false,
    roles: null
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_ROLES(state, option) {
      Vue.set(state, "roles", option);
    },
    IS_READY(state, isLoading) {
      state.isReady = isLoading;
    }
  },
  actions: {
    reset({ state, commit, rootState }) {
      commit("RESET");
    },
    fetchRoles({ state, commit, rootState }) {
      if (
        !(
          rootState.user &&
          rootState.user.loggedUser &&
          "contract_id" in rootState.user.loggedUser
        ) ||
        state.roles instanceof Array
      )
        return;
      let query = {
        contract_id: rootState.user.loggedUser.contract_id
      };
      commit("IS_READY", false);
      let srv = new RoleService();
      return srv.fetchRoles(query).then((data) => {
        if (data && data.length) {
          commit("SET_ROLES", data);
        }
        commit("IS_READY", true);
      });
    }
  },
  getters: {
    isReady(state) {
      return state.isReady;
    },
    roles(state) {
      return state.roles ?? [];
    }
  }
};

import { AbilityBuilder } from "@casl/ability";
/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 * 
 * This Ability will be used as the default and empty abilities 
 * When user get logged it should bring a "role"
 * abilities rules are based on user roles
 
  https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html

  export default AbilityBuilder.define({ subjectName }, can => {
      can(['read', 'create'], 'Todo')
      can(['update', 'delete'], 'Todo', { assignee: 'me' })
  })

  RULES can read from REST and updated
    JSON.stringify(window.ability.rules)
    "[{"actions":["read","create"],"subject":"Todo"},{"actions":["update","delete"],"subject":"Todo","conditions":{"assignee":"me"}}]"
  And then update the default ability:

  cannot example:
    can('read', 'all');
    cannot('write', 'all');
  
  results:
  [
    {
      "actions":"read",
      "subject":["all"]
    },
    {
      "actions":"write",
      "subject":["all"],
      "inverted":true
    }
  ]
  
  "
  to update rest based rules:    
    this.$ability.update(rules);
*/
function subjectName(item) {
  var itemName = "";
  if (!item || typeof item === "string") {
    itemName = item;
  } else {
    itemName =
      ("name" in item && item.name) ||
      ("$options" in item && "name" in item.$options && item.$options.name) ||
      ("id" in item && item.id) ||
      ("__type" in item && item.__type) ||
      item;
  }
  ////console.log(itemName);
  return itemName;
}
export default AbilityBuilder.define({ subjectName }, (can, cannot) => {
  // empty as will be set as user logged in (dashboard)
});

import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
const simulator = (lst) => {
  window.dev_flag = window.dev_flag ? false : true;
  lst.forEach((item) => {
    item.connected = window.dev_flag;
    item.enabled = window.dev_flag;
  });
};
*/

/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
*/

const baseurl = ""; //v1  set to "" after conversion

export default class ReasonService {
  async fetch(query, URL) {
    return new Promise((resolve) => {
      let url = URL || `${baseurl}reasons/?format=json`;
      let auth = new Auth();
      if (query) {
        if (query.filters && typeof query.filters == "object") {
          query = { ...query, ...query.filters };
          delete query.filters;
        }
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          resolve(response?.body?.length ? response.body : []);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    return new Promise((resolve) => {
      let url = `${baseurl}reasons/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.patch(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async export(params, fname) {
    let self = this;
    fname = fname || "reason.csv";
    return new Promise((resolve) => {
      let url = `${baseurl}reasons/export/?${params}`; //v1
      let auth = new Auth();
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            _vue.$utils.download(response.bodyText, "text/csv", fname);
            return;
          }
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async import(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}reasons/import/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

}

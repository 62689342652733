import { helperActionsFactory } from "./helpers";

export const actions = {
  /**
   * @typedef {Object} ActionParams
   * @property {Object} control Target control
   * @property {Object} getters Store getters
   * @property {Object} anchorControl Reference control for the dimensions
   */
  /**
   * Set selected controls height the same of {anchorControl}
   *
   * @param {ActionParams} params Action parameters
   */
  sameHeight({ control, anchorControl }) {
    control.synopticComponent.clientRect.height =
      anchorControl.synopticComponent.clientRect.height;
  },
  /**
   * Set selected controls width the same of {anchorControl}
   *
   * @param {ActionParams} params Action parameters
   */
  sameWidth({ control, anchorControl }) {
    control.synopticComponent.clientRect.width =
      anchorControl.synopticComponent.clientRect.width;
  },
  /**
   * Set selected controls width and height the same of {anchorControl}
   *
   * @param {ActionParams} params Action parameters
   */
  sameSize({ control, anchorControl }) {
    control.synopticComponent.clientRect.width =
      anchorControl.synopticComponent.clientRect.width;
    control.synopticComponent.clientRect.height =
      anchorControl.synopticComponent.clientRect.height;
  }
};

export default helperActionsFactory(actions);

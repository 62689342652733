// import Vue from "vue";
import Utils from "@/plugins/utils.js";
import { debounce } from "lodash";

const src = `${document.location.origin}/static/common/lib/js/mqttws31.js`;
const DEBUG = false;
export default (() => {
  class MQTT {
    constructor(options) {
      let self = this;

      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      this._topics = null;

      this.onStatusChanged = options?.onStatusChanged || null;

      this.onMessage = options?.onMessage || null;

      this.setStatus("IDLE");
      // inject
      if (window.Paho) {
        setTimeout(() => {
          self.setStatus("READY"); // must but in another thread once it is running on constructor
        }, 0);
      } else {
        Utils.injectScript(src)
          .then(() => {
            if (window.Paho) {
              self.setStatus("READY");
            }
          })
          .catch((e) => {
            self.setStatus("ERROR", e);
          });
      }
      return this;
    }

    log(t) {
      if (DEBUG) console.log(`${new Date().getTime()} ${t}`);
    }

    setStatus(status, details) {
      this.log(status);
      this._status = status;
      this._details = details || "";
      if (this.onStatusChanged) {
        try {
          (async () => {
            this.onStatusChanged(this._status, this._details);
          })();
        } catch (e) { }
      }
      return this;
    }

    get status() {
      return this._status;
    }

    subscribe(topics, cb) {
      let lst = [...(topics || [])];
      lst = lst.filter((topic) => !(this._topics || []).some((i) => i === topic));

      const _run = (topic, callback) => {
        try {
          if (this._status !== 'CONNECTED') {
            callback();
            return;
          }
          this._client.subscribe(topic, {
            onSuccess: () => {
              this.log(`subscribed ${topic}`);
              if (!(this._topics || []).some((i) => i === topic)) {
                this._topics = this._topics || [];
                this._topics.push(topic);
              }
              callback();
            },
            onFailure: () => {
              this.log(`subscribe fail for ${topic}`);
              callback();
            }
          });
        } catch (error) {
          callback();
        }
      };

      const _s = (topic) => {
        if (topic === undefined) {
          if (cb && typeof cb == "function") {
            cb();
          }
          return;
        }
        if (this._status !== 'CONNECTED') {
          _s(lst.shift());
          return;
        }
        _.delay(_run, 10, topic, () => {
          _s(lst.shift());
        });
      }
      _s(lst.shift());
    }

    unsubscribe(topics, cb) {
      let lst = [...(topics || [])];
      const _s = (topic) => {
        if (topic === undefined) {
          if (cb && typeof cb == "function") {
            cb();
          }
          return;
        }
        this._client.unsubscribe(topic, {
          onSuccess: () => {
            this.log(`unsubscribed ${topic}`);
            this._topics = (this._topics || []).filter((i) => i !== topic);
            _s(lst.shift());
          },
          onFailure: () => {
            this.log(`ERROR: unsubscribe fail for ${topic}`);
            // this._topics = (this._topics || []).filter((i) => i !== topic);
            _s(lst.shift());
          }
        });
      };
      _s(lst.shift());
    }

    unsubscribeAll(cb) {
      this.unsubscribe(this._topics, () => {
        this._topics = null;
        if (cb && typeof cb == "function") {
          cb();
        }
      });
    }

    subscribeOnlyOnTopics(topics, cb) {
      let _cb = cb || (() => { });
      // move subscription to the begin of the list
      let lst = [
        ...(topics || []),
        ...(this._topics || []).filter(
          (topic) => !(topics || []).some((i) => i === topic)
        )
      ];

      const _run = (topic, callback) => {
        try {
          if (this._status !== 'CONNECTED') {
            callback();
            return;
          }
          this._client.unsubscribe(topic, {
            onSuccess: () => {
              this.log(`unsubscribed ${topic}`);
              this._topics = (this._topics || []).filter((i) => i !== topic);
              callback();
            },
            onFailure: () => {
              this.log(`ERROR: unsubscribe fail for ${topic}`);
              // this._topics = (this._topics || []).filter((i) => i !== topic);
              callback();
            }
          });
        } catch (error) {
          callback();
        }
      };

      const _s = (topic) => {
        // this.log(new Date().getTime())
        if (topic === undefined) {
          if ((this?._topics?.length ?? 0) < (topics || []).length) {
            this.subscribe(topics, _cb);
          }
          else if (_cb && typeof _cb == "function") {
            _cb();
          }
          return;
        }
        if ((topics || []).some((i) => i == topic) || this._status !== 'CONNECTED') {
          _s(lst.shift());
          return;
        }
        _.delay(_run, 10, topic, () => {
          _s(lst.shift());
        });
      };
      _s(lst.shift());
    }

    onConnected() {
      this._topics = null;
      this.setStatus("CONNECTED");
    }

    onFailure(e) {
      if (this._config && this._config.reconnectTimeout && this._client) {
        this.setStatus("ERROR", e);
        setTimeout(
          () => {
            this.connect();
          },
          this._config.reconnectTimeout,
          this
        );
      }
    }

    onDisconnected(e) {
      let self = this;
      this.setStatus("DISCONNECTED", e);
      if (this._client) {
        setTimeout(() => {
          if (this._client) {
            self.connect();
          }
        }, this._config.reconnectTimeout);
      }
    }

    onMessageArrived(info) {
      try {
        if (this._status !== 'CONNECTED') return;
        this.log(`onMessageArrived ${info.destinationName}`);
        const smsg = info ? info?.payloadString : "";
        // this.log(smsg);
        if (smsg) {
          let msg = JSON.parse(smsg);
          (async (msg) => {
            this.onMessage(msg, info);
          })((typeof msg === 'string' && msg == 'null') ? null : msg);
        }
      } catch (e) {
        // this.log(e)
      }
    }

    connect() {
      let self = this;
      let options = null;
      let map = {
        userName: "userName",
        password: "password",
        encrypted: "useSSL",
        keepAliveInterval: "keepAliveInterval"
      };
      for (let k in map) {
        if (k in this._config) {
          options = options || {};
          options[map[k]] = this._config[k];
        }
      }
      this.setStatus("CONNECTING");
      if (this._client) {
        if (!this._client.onMessageArrived) {
          this._client.onMessageArrived = (data) => {
            self.onMessageArrived(data);
          };
        }
        if (!this._client.onConnectionLost) {
          this._client.onConnectionLost = (e) => {
            self.onDisconnected(e);
          };
        }
        let cfg = {
          onSuccess: (data) => {
            self.onConnected(data);
          },
          onFailure: (e) => {
            self.onFailure(e);
          },
          ...(options || {})
        };
        this._client.connect(cfg);
      }
    }

    setup(cfg) {
      // this._topics = (cfg || {})?.topics || [];
      this._topics = null;
      this._config = {
        host: (cfg || {})?.websocket?.host || "",
        port: (cfg || {})?.websocket?.port || "",
        encrypted: (cfg || {})?.websocket?.encrypted || false,
        reconnectTimeout: (cfg || {})?.websocket?.reconnectTimeout || 2000,
        clientId: (cfg || {})?.clientId || Utils.uuid(),
        userName: (cfg || {})?.userName || "",
        password: (cfg || {})?.password || ""
      };
      if (window.Paho) {
        this._client = new window.Paho.MQTT.Client(
          this._config.host,
          Number(this._config.port),
          this._config.clientId
        );
        this.connect();
      }
    }

    publish(topic, msg) {
      try {
        let message = new window.Paho.MQTT.Message(JSON.stringify(msg));
        message.destinationName = topic;
        debounce(function() {
          this._client.send(message);
        }, 100);
      } catch (e) {
        this.log(e);
      }
    }

    disconnect() {
      this._client.disconnect();
    }

    destroy() {
      if (this._client && this._client.isConnected()) {
        this._client.disconnect();
      }
      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      if (this.onStatusChanged) {
        this.onStatusChanged = () => { }
      }
      if (MQTT.instance) {
        delete MQTT.instance;
        MQTT.instance = null;
      }
    }
  }

  return {
    // Singleton factory
    MQTT(options) {
      if (!MQTT.instance) {
        MQTT.instance = new MQTT(options);
      }
      return MQTT.instance;
    }
  };
})();

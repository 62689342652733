import Vue from "vue";
import VueResource from "vue-resource";
import UserService from "@/services/user";
import Utils from "@/plugins/utils.js";
import { parseMessage } from "@/utils";

Vue.use(VueResource);
Vue.use(Utils);

const baseurl = ""; //v1  set to "" after conversion

const doGet = async (authSrv, endpoint, id) => {
  return new Promise((resolve) => {
    let url = `${baseurl}${endpoint}/${id}/?format=json`;
    Vue.http.get(url, authSrv.requestOptions()).then(
      (response) => {
        if (response && response.body) {
          resolve(response.body);
          return;
        }
        resolve(null);
      },
      (error) => {
        resolve(null);
      }
    );
  });
};

const doRemove = async (authSrv, endpoint, payload) => {
  let entry;
  if (payload?.resource_ids?.length) {
    entry = {
      resource_ids: payload.resource_ids,
      contract_id: payload.contract_id,
      remove_type: "partial"
    };
  } else {
    if (payload.id && !payload.etag) {
      entry = await doGet(authSrv, endpoint, payload.id);
    } else {
      entry = { id: payload.id, etag: payload.etag };
    }
  }
  return new Promise((resolve) => {
    let request = null;
    if (entry?.id) {
      request = Vue.http.delete(
        `${baseurl}${endpoint}/${entry.id}/?format=json`,
        authSrv.requestOptions(entry.etag ? { "If-Match": entry.etag } : null)
      );
    } else if (entry.resource_ids) {
      request = Vue.http.post(
        `${baseurl}${endpoint}/remove/?format=json`,
        entry,
        authSrv.requestOptions()
      );
    }
    if (!request) {
      resolve(null);
      return;
    }
    request.then(
      (ret) => {
        resolve(ret ? {} : null);
      },
      (error) => {
        resolve(
          error.body?.detail ||
          parseMessage(error.body ?? {}) ||
          "Failed to delete"
        );
      }
    );
  });
};

export { doRemove, doGet };

export default class Auth {
  /*
  Return a signed VueResource RequestOptions object
  @params headers (Object) if provided will be merged with built headers
  */

  isLocalhost() {
    return (
      document.location.hostname == "localhost" ||
      document.location.hostname == "127.0.0.1" ||
      document.location.hostname == "0.0.0.0"
    );
  }

  requestOptions(headers) {
    let self = this;
    let options = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    let access_token = self.access_token();
    if (access_token) {
      options.headers.Authorization = `Bearer ${access_token}`;
    }
    // if any extra header is provide - add it
    if (headers) {
      for (var i in headers) {
        options.headers[i] = headers[i];
      }
    }
    return options;
  }

  access_token(user) {
    if (user && user.token && user.expiration_at) {
      Vue.$cookies.set(
        "access_token",
        user.token,
        new Date(user.expiration_at),
        '' /*path*/,
        ''/* domain*/,
        true/* secure*/,
        "none"/*sameSite*/
      );
    } else {
      let access_token = Vue.$cookies.get("access_token");
      if (access_token) {
        return access_token;
      }
      let tmpVue = new Vue();
      access_token = tmpVue.$utils.gup("access_token");
      return access_token || "";
    }
  }

  logout() {
    Vue.$cookies.remove("access_token");
    this.setKeepAlive(); // reset the keep alive option
  }

  // Turn On/Off the option to keep connection alive
  setKeepAlive(value) {
    if (value) {
      window.localStorage.setItem("keep_alive", true);
    } else {
      window.localStorage.removeItem("keep_alive");
    }
  }

  keepAlive() {
    return window.localStorage.getItem("keep_alive") ? true : false;
  }

  async refreshToken(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/login_refresh/`;
      let request = null;
      if (payload) {
        request = Vue.http.post(url, payload, self.requestOptions()).then(
          (response) => {
            if (response && response.body && response.body) {
              self.access_token(response.body);
              resolve(response.body);
            } else {
              resolve(null);
            }
          },
          (error) => {
            resolve(null);
          }
        );
        return;
      }
      resolve(null);
    });
  }

  async login(cred, keep_alive) {
    let self = this;
    return new Promise((resolve, reject) => {
      let url = `${baseurl}auth/login/`;
      let request = null;
      let payload = null;
      if (cred) {
        payload = cred;
        let contract_id =
          (Vue.http.options.config && Vue.http.options.config.contract_id) ||
          "";
        if (contract_id) {
          payload.contract_id = contract_id;
        }
        request = Vue.http.post(url, payload);
      } else {
        if (self.access_token()) {
          request = Vue.http.post(url, payload, self.requestOptions());
        }
      }
      if (request) {
        request.then(
          (response) => {
            // test: add this message to your api-messages.js file - maz
            // reject("test: error message"); return;
            if (response && response.body) {
              if (!response.body?.token) {
                reject({
                  "status": response.status,
                  "message": "login_forbidden_access"
                });
                return;
              }
              // save token
              if (
                !response.body.is_public_access ||
                !Vue.$cookies.get("access_token")
              ) {
                self.access_token(response.body);
              }
              // return user
              let srv = new UserService();
              let user = srv.userAdapter(response.body);
              self.setKeepAlive(keep_alive);
              resolve(user);
            } else {
              resolve(null);
            }
          },
          (error) => {
            let message =
              error.message ||
              error?.body?.detail ||
              error?.body?.non_field_errors?.[0];

            if (!message && error.status == 0) {
              message = "no_connection";
            } else if (!message) {
              message = "unknown_error";
            }

            if (error.status == 429) {
              message = "rate_limit:" + message.match(/\d+/)[0];
            } else if (message.match(/\d+/)) {
              message = "login_attempt_limit:" + message.match(/\d+/)[0];
            }

            reject({
              "status": error.status,
              "message": message
            });
          }
        );
        return;
      }
      reject(null);
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/registration/`; //v1
      let request = Vue.http.post(url, payload);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async activate(payload) {
    let self = this;
    return new Promise((resolve) => {
      let headers = {};
      let url = `${baseurl}auth/activate/`;
      Vue.http.post(url, payload).then(
        (response) => {
          resolve(true);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async request_activation(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/resend_activation_mail/`; //v1
      let request = Vue.http.post(url, payload);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async request_password_link(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/password_reset_send_link/`; //v1
      let request = Vue.http.post(url, payload);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async request_password_reset(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}auth/password_reset/`; //v1
      let request = Vue.http.post(url, payload);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Unknown error"
          );
        }
      );
    });
  }

  async changePassword(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `auth/password_change/`;
      let request = Vue.http.post(url, payload, self.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve({ msg: response.body.detail || response.body });
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(error.body?.detail ?? Object.entries(error.body));
        }
      );
    });
  }

  async validateToken(userId, id) {
    return new Promise((resolve, reject) => {
      let url = `${baseurl}auth/user_check_token/${userId}/${id}/?format=json`; //v1
      let request = Vue.http.get(url);
      request.then(
        (response) => {
          resolve(response.body);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async fetchAuthPolicies() {
    return new Promise((resolve, reject) => {
      let url = `${baseurl}password_policy/?format=json`;
      let request = Vue.http.get(url);
      request.then(
        (response) => {
          resolve(response.body);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async remove(payload) {
    return new Promise((resolve) => {
      let url = `${baseurl}devices/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
              parseMessage(error.body ?? {}) ||
              "Failed to delete"
            );
          }
        );
    });
  }

  async validateCredentials(uid, usr, pwd) {
    return new Promise((resolve, reject) => {
      let url = `${baseurl}auth/users/${uid}/?format=json`;
      let options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(usr + ":" + pwd)}`
        }
      };
      let request = Vue.http.get(url, options);
      request.then(
        (response) => {
          resolve(response.body);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

import AuthService from "@/services/auth";
import Vue from "vue";
import VueResource from "vue-resource";

Vue.use(VueResource);

export function debounceTime(milliseconds, fn) {
  let timer = 0;
  return function() {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this), milliseconds, ...arguments);
  };
}

export function parseMessage(obj, translateProp) {
  return Object.entries(obj).reduce(
    (msg, [prop, value]) =>
      msg +
      `${
        typeof translateProp == "function" ? translateProp(prop) : prop
      }: ${value}\n`,
    ""
  );
}

export function writeToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

export function readFromClipboard() {
  return navigator.clipboard.readText();
}

export function requestSafeResource(resource, options = {}) {
  let auth = new AuthService();
  return Vue.http.get(resource, { ...options, ...auth.requestOptions() });
}

export function whichTransitionEvent(event = "end") {
  let el = document.createElement("fakeelement");
  let transitions = {
    transition: `transition${event}`,
    OTransition: `otransition${event}`,
    MozTransition: `transition${event}`,
    WebkitTransition: `webkitTransition${capitalizeFirst(event)}`
  };

  for (let t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

export function capitalizeFirst(str) {
  return str[0].toUpperCase() + str.substring(1, str.length);
}

export function isUUID(uuid) {
  // return /\d[A-Za-z]|[A-Za-z]\d/.test(uuid) && uuid.length == 8;
  // return (
  //   uuid.length == 8 &&
  //   (/\d[A-Fa-f]|[A-Fa-f]\d/.test(uuid) || /^\d+$/.test(uuid))
  // ); // uuid.uuid4().hex[:8] # python function might returns digit only string
  return /^[0-9a-fA-F]{8}$/.test(uuid);
}

export function pipe(...fns) {
  return (arg) => fns.reduce((result, fn) => fn(result), arg);
}

export function clampDownPosition(rectOrEl, position, edgeOffset = {}) {
  if (typeof rectOrEl != "object") return;

  let rect =
    rectOrEl instanceof HTMLElement
      ? rectOrEl.getBoundingClientRect()
      : rectOrEl;
  if (rect.bottom == undefined) rect.top = rect.top ?? 0;
  if (rect.right == undefined) rect.left = rect.left ?? 0;

  if (!position || typeof position != "object")
    position = { top: rect.top + "px", left: rect.left + "px" };

  edgeOffset = {
    top:
      0 + (edgeOffset.top ?? (typeof edgeOffset == "number" ? edgeOffset : 0)),
    left:
      0 + (edgeOffset.left ?? (typeof edgeOffset == "number" ? edgeOffset : 0)),
    right:
      window.innerWidth -
      (edgeOffset.right ?? (typeof edgeOffset == "number" ? edgeOffset : 0)),
    bottom:
      window.innerHeight -
      (edgeOffset.bottom ?? (typeof edgeOffset == "number" ? edgeOffset : 0))
  };

  let prop, diff;
  // check if it's beyond top edge (given minimum offset)
  if (Math.max(rect.top, edgeOffset.top) != rect.top) {
    diff = Math.abs(rect.top) + edgeOffset.top;
    if (position.top != undefined) {
      prop = "top";
    } else {
      prop = "bottom";
      diff = -diff;
    }
    // check if it's beyond bottom edge (given minimum offset)
  } else if (Math.min(rect.bottom, edgeOffset.bottom) != rect.bottom) {
    diff = rect.bottom - edgeOffset.bottom;
    if (position.top != undefined) {
      prop = "top";
      diff = -diff;
    } else {
      prop = "bottom";
    }
  }

  // apply relative difference if is needed
  // to correct property
  if (diff) {
    position[prop] = Math.floor(parseInt(position[prop]) + diff) + "px";
    diff = null;
  }

  // check if it's beyond left edge (given minimum offset)
  if (Math.max(rect.left, edgeOffset.left) != rect.left) {
    diff = Math.abs(rect.left) + edgeOffset.left;
    if (position.left != undefined) {
      prop = "left";
    } else {
      prop = "right";
      diff = -diff;
    }
    // check if it's beyond right edge (given minimum offset)
  } else if (Math.min(rect.right, edgeOffset.right) != rect.right) {
    diff = rect.right - edgeOffset.right;
    if (position.left != undefined) {
      prop = "left";
      diff = -diff;
    } else {
      prop = "right";
    }
  }

  // apply relative difference if is needed
  // to correct property
  if (diff) {
    position[prop] = Math.floor(parseInt(position[prop]) + diff) + "px";
  }

  return position;
}

/**
 * Check if event target and its parent tree matches a composed selector
 * @param {Event} event click event
 * @param {string} composedSelector composed selector (e.g. 'ul.nested li.selector')
 * @returns weather or not the selector matches the element
 */

export function eventMatchesSelector(event, composedSelector) {
  let path = event.path || (event.composedPath && event.composedPath());

  if (!(path instanceof Array) || typeof composedSelector !== "string")
    return false;

  let selectors = composedSelector.split(" ").reverse();
  let lastIndex = -1;

  for (let selector of selectors) {
    lastIndex = path
      .slice(lastIndex + 1)
      .findIndex((el) => el.matches?.(selector));
    if (lastIndex == -1) {
      return false;
    }
  }
  return true;
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

import cloneDeep from "lodash/cloneDeep";
import mapValues from "lodash/mapValues";

export function findExtremeControls(controls, axis) {
  const getProp = (c) => {
    let rect = c.synopticComponent.clientRect;
    if (["left", "top"].includes(axis)) return rect[axis];
    if (axis === "right") return rect.left + rect.width;
    if (axis === "bottom") return rect.top + rect.height;
    if (axis === "hcenter") return rect.left + rect.width / 2;
    if (axis === "vcenter") return rect.top + rect.height / 2;
  };
  const sortedControls = cloneDeep(controls).sort(
    (a, b) => getProp(a) - getProp(b)
  );
  const firstControl = sortedControls.at(0);
  const lastControl = sortedControls.at(-1);
  return { firstControl, lastControl, sortedControls };
}

export const actions = {
  /**
   * Distributes controls left sides evenly
   */
  distributeHorizontalLeft({ firstControl, lastControl, sortedControls }) {
    const smallestLeft = firstControl.synopticComponent.clientRect.left;
    const highestLeft = lastControl.synopticComponent.clientRect.left;
    const totalDistance = highestLeft - smallestLeft;
    const evenDistance = totalDistance / (sortedControls.length - 1);

    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const left = Math.round(smallestLeft + evenDistance * index);

      control.synopticComponent.clientRect.left = left;
    });
  },
  /**
   * Distributes controls centers evenly in horizontal axis
   */
  distributeHorizontalCenter({ firstControl, lastControl, sortedControls }) {
    const smallestCenter =
      firstControl.synopticComponent.clientRect.left +
      firstControl.synopticComponent.clientRect.width / 2;
    const highestCenter =
      lastControl.synopticComponent.clientRect.left +
      lastControl.synopticComponent.clientRect.width / 2;
    const totalDistance = highestCenter - smallestCenter;
    const evenDistance = totalDistance / (sortedControls.length - 1);
    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const left = Math.round(
        smallestCenter +
          evenDistance * index -
          control.synopticComponent.clientRect.width / 2
      );

      control.synopticComponent.clientRect.left = left;
    });
  },
  /**
   * Distributes controls right sides evenly
   */
  distributeHorizontalRight({ firstControl, lastControl, sortedControls }) {
    const smallestRight =
      firstControl.synopticComponent.clientRect.left +
      firstControl.synopticComponent.clientRect.width;
    const highestRight =
      lastControl.synopticComponent.clientRect.left +
      lastControl.synopticComponent.clientRect.width;
    const totalDistance = highestRight - smallestRight;
    const evenDistance = totalDistance / (sortedControls.length - 1);
    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const left = Math.round(
        smallestRight +
          evenDistance * index -
          control.synopticComponent.clientRect.width
      );

      control.synopticComponent.clientRect.left = left;
    });
  },
  /**
   * Distributes horizontal distance between controls evenly
   */
  distributeHorizontalGap({ firstControl, lastControl, sortedControls }) {
    const smallestRight =
      firstControl.synopticComponent.clientRect.left +
      firstControl.synopticComponent.clientRect.width;
    const highestLeft = lastControl.synopticComponent.clientRect.left;
    const totalDistance = highestLeft - smallestRight;
    const sumOfWidths = sortedControls.reduce(
      (sum, control, index) =>
        [0, sortedControls.length - 1].includes(index)
          ? sum
          : sum + control.synopticComponent.clientRect.width,
      0
    );
    const totalGapDistance = totalDistance - sumOfWidths;
    const evenDistance = totalGapDistance / (sortedControls.length - 1);
    sortedControls.reduce((totalWidth, control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id))
        return totalWidth;

      const left = Math.round(
        smallestRight + evenDistance * index + totalWidth
      );

      control.synopticComponent.clientRect.left = left;

      return totalWidth + control.synopticComponent.clientRect.width;
    }, 0);
  },
  /**
   * Distributes controls top sides evenly
   */
  distributeVerticalTop({ firstControl, lastControl, sortedControls }) {
    const smallestTop = firstControl.synopticComponent.clientRect.top;
    const highestTop = lastControl.synopticComponent.clientRect.top;
    const totalDistance = highestTop - smallestTop;
    const evenDistance = totalDistance / (sortedControls.length - 1);
    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const top = Math.round(smallestTop + evenDistance * index);

      control.synopticComponent.clientRect.top = top;
    });
  },
  /**
   * Distributes controls centers evenly in vertical axis
   */
  distributeVerticalCenter({ firstControl, lastControl, sortedControls }) {
    const smallestCenter =
      firstControl.synopticComponent.clientRect.top +
      firstControl.synopticComponent.clientRect.height / 2;
    const highestCenter =
      lastControl.synopticComponent.clientRect.top +
      lastControl.synopticComponent.clientRect.height / 2;
    const totalDistance = highestCenter - smallestCenter;
    const evenDistance = totalDistance / (sortedControls.length - 1);
    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const top = Math.round(
        smallestCenter +
          evenDistance * index -
          control.synopticComponent.clientRect.height / 2
      );

      control.synopticComponent.clientRect.top = top;
    });
  },
  /**
   * Distributes controls bottom sides evenly
   */
  distributeVerticalBottom({ firstControl, lastControl, sortedControls }) {
    const smallestBottom =
      firstControl.synopticComponent.clientRect.top +
      firstControl.synopticComponent.clientRect.height;
    const highestBottom =
      lastControl.synopticComponent.clientRect.top +
      lastControl.synopticComponent.clientRect.height;
    const totalDistance = highestBottom - smallestBottom;
    const evenDistance = totalDistance / (sortedControls.length - 1);
    sortedControls.forEach((control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id)) return;

      const top = Math.round(
        smallestBottom +
          evenDistance * index -
          control.synopticComponent.clientRect.height
      );

      control.synopticComponent.clientRect.top = top;
    });
  },
  /**
   * Distributes vertical distance between controls evenly
   */
  distributeVerticalGap({ firstControl, lastControl, sortedControls }) {
    const smallestBottom =
      firstControl.synopticComponent.clientRect.top +
      firstControl.synopticComponent.clientRect.height;
    const highestTop = lastControl.synopticComponent.clientRect.top;
    const totalDistance = highestTop - smallestBottom;
    const sumOfHeights = sortedControls.reduce(
      (sum, control, index) =>
        [0, sortedControls.length - 1].includes(index)
          ? sum
          : sum + control.synopticComponent.clientRect.height,
      0
    );
    const totalGapDistance = totalDistance - sumOfHeights;
    const evenDistance = totalGapDistance / (sortedControls.length - 1);
    sortedControls.reduce((totalHeight, control, index) => {
      if ([firstControl.id, lastControl.id].includes(control.id))
        return totalHeight;

      const top = Math.round(
        smallestBottom + evenDistance * index + totalHeight
      );

      control.synopticComponent.clientRect.top = top;

      return totalHeight + control.synopticComponent.clientRect.height;
    }, 0);
  }
};

export default mapValues(actions, (action, name) => ({ getters, dispatch }) => {
  // get corresponding rect property to find
  // extreme controls based on function name
  const propMap = {
    horizontalcenter: "hcenter",
    verticalcenter: "vcenter",
    horizontal: "left",
    vertical: "top",
    right: "right",
    bottom: "bottom"
  };
  /*
    left = distribute horizontal left and gap
    right = distribute horizontal right
    top = distribute vertical top and gap
    bottom = distribute horizontal bottom
    hcenter = distribute horizontal center
    vcenter = distribute vertical center
  */
  const matchResult = name
    .match(
      /right|bottom|horizontalcenter|verticalcenter|horizontal(?!right)|vertical(?!bottom)/i
    )[0]
    .toLowerCase();
  const prop = propMap[matchResult];
  const extremeControls = findExtremeControls(getters.selectedControls, prop);

  action(extremeControls);

  dispatch("updateControls", { controls: extremeControls.sortedControls });
});

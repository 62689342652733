import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { parseMessage } from "@/utils";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion
const alarmDefs = {
  alarm_unack: 1,
  alarm_ack: 2,
  alarm_ret_unack: 3
};
export { alarmDefs };
export default class AlarmService {
  async fetch(iQuery, URL) {
    return new Promise((resolve) => {
      // https://api.dev.telemetria.webhi.com.br/rest/v1/alarms/?format=json&mode=alarm_panel&contract_id=10
      // let url = URL || `${baseurl}alarms/?format=json&hierarchy=false`;
      let url = URL || `${baseurl}alarms/?format=json`;
      let auth = new Auth();
      let stateOnly = "only_state" in (iQuery || {});

      var query = stateOnly
        ? iQuery || {}
        : { ...{ mode: "alarm_panel" }, ...(iQuery || {}) };
      for (var prop in query) {
        let vlr = encodeURIComponent(query[prop]);
        url += `&${prop}=${vlr}`;
      }

      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            let lst = stateOnly
              ? response.body.map((alarm) => {
                  if (alarm?.last_transition_at?.length == 19) {
                    alarm.last_transition_at += "+00:00";
                  }
                  return alarm;
                })
              : response.body;
            resolve(lst);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async save(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/`; //v1
      let request = null;
      let auth = new Auth();
      if (("id" in payload && payload.id) || payload.length) {
        let lst = (payload.id ? [payload] : payload).filter(
          ({ id, etag }) => (id && etag && true) || false
        );
        if (!lst.length) {
          resolve("invalid_data_set");
          return;
        }
        request = Vue.http.patch(url, { data: lst }, auth.requestOptions());
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async export(params, fname) {
    let self = this;
    fname = fname || "alarm.csv";
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/export/?${params}`; //v1
      let auth = new Auth();
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            _vue.$utils.download(response.bodyText, "text/csv", fname);
            return;
          }
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async import(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/import/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async duplicate(id, payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/${id}/duplicate/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(
        url,
        payload,
        auth.requestOptions(payload.etag ? { "If-Match": payload.etag } : null)
      );
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async get(id, contract_id) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    if (!payload.etag) {
      let item = await this.get(payload.id, payload.contract_id);
      if (item) {
        payload.etag = item.etag;
      }
    }
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
                parseMessage(error.body ?? {}) ||
                "Failed to delete"
            );
          }
        );
    });
  }

  async removeMultiple(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}alarms/remove/`; //v1
      let auth = new Auth();
      Vue.http
        .post(
          url,
          payload,
          auth.requestOptions()
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
                parseMessage(error.body ?? {}) ||
                "Failed to delete"
            );
          }
        );
    });
  }

  async history(query, URL) {
    let self = this;
    return new Promise((resolve) => {
      let url = URL || `${baseurl}alarms_history/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }
}

import Vue from "vue";
import EquipmentDataService from "../services/equipment-data";
import DataService from "../services/data.js";
import { connect } from "echarts";

function initialState() {
  return {
    isLoading: false,
    saveStatus: "ready",
    pendingTransactionList: [],
    task: null,
    history: null
  };
}

const _tasktimer = null;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    IS_LOADING(state, option) {
      state.isLoading = option;
    },
    SET_SAVE_STATUS(state, option) {
      state.saveStatus = option; // 'ready' | 'busy' | 'error' | 'success'
    },
    ADD_PENDING_TRANSACTION(state, entry) {
      var lst = state.pendingTransactionList;
      lst.push(entry);
      state.pendingTransactionList = lst;
    },
    DEL_PENDING_TRANSACTION(state, entry) {
      var lst = state.pendingTransactionList.filter(function(i) {
        return i.data_id != entry.data_id;
      });
      state.pendingTransactionList = lst;
    },
    SET_TASK(state, entry) {
      if (entry) {
        let str = JSON.stringify(entry);
        Vue.set(state, "task", JSON.parse(str));
        window.localStorage.setItem("task", btoa(str));
      } else {
        Vue.set(state, "task", null);
        window.localStorage.removeItem("task");
      }
    },
    SET_HISTORY(state, entry) {
      state.history = entry;
    }
  },
  actions: {
    reset(context) {
      context.commit("RESET");
    },
    fetchEquipmentDataTransaction(context, query) {
      let srv = new EquipmentDataService();
      srv.fetchEquipmentDataTransaction(query).then((entry) => {
        if (entry && "data" in entry && entry.data && entry.data.length) {
          var data =
            "config_dado" in entry.data[0] &&
            entry.data[0].config_dado.length &&
            entry.data[0].config_dado[0];
          var entry = {
            data_id: data && "dados" in data && data.dados.id,
            value: data && "dados" in data && data.dados[0].valor,
            transaction_id: entry.data.id
          };
          context.commit("ADD_PENDING_TRANSACTION", entry);
        } else {
          context.commit("DEL_PENDING_TRANSACTION", query);
        }
      });
    },
    checkTask(context) {
      let prev = window.localStorage.getItem("task");
      if (prev) {
        prev = JSON.parse(atob(prev));
      }
      let query = context.state.task || prev;
      if (query && query.task_id) {
        let srv = new DataService();
        srv
          .checkTask(query.task_id, { contract_id: query.contract_id })
          .then((response) => {
            if (response) {
              if (typeof response == "object") {
                if ("file_path" in response) {
                  query.file_path = response?.file_path;
                  query.is_limit_reached = response?.is_limit_reached;
                  context.commit("SET_TASK", query);
                } else {
                  if (!query.error) {
                    setTimeout(() => {
                      if (window.localStorage.getItem("task")) {
                        context.dispatch("checkTask");
                      }
                    }, 10000);
                  }
                }
              } else if (typeof response == "string") {
                delete query.file_path;
                query.error = response;
                context.commit("SET_TASK", query);
              }
            }
          });
      }
    },
    createTask(context, query) {
      let srv = new DataService();
      context.commit("SET_TASK", query);
      srv.fetchSamples(query).then((response) => {
        if (response) {
          if (query.download_file && response.task_id) {
            query.task_id = response.task_id;
            context.commit("SET_TASK", query);
            context.dispatch("checkTask");
          }
        }
      });
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    saveStatus(state) {
      return state.saveStatus;
    },
    pendingTransactionList(state) {
      return state.pendingTransactionList;
    },
    task(state) {
      let prev = window.localStorage.getItem("task");
      if (prev) {
        prev = JSON.parse(atob(prev));
      }
      return state.task || prev || null;
    },
    history(state) {
      return state.history;
    }
  }
};

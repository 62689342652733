import { helperActionsFactory } from "./helpers";

const swap = (lst, control, where) => {
  let a = (lst || []).findIndex(({ id }) => id == control.id);
  let b = where == 0 ? a + 1 : where == 1 ? a - 1 : where == 2 ? 0 : lst.length - 1;
  if (a < 0 || b >= lst.length || b < 0) return;
  const _b = lst[b];
  lst[b] = lst[a];
  lst[a] = _b;
};

export const actions = {
  bringToFront({ control, getters }) {
    let lst = getters.controls;
    let i = (lst || []).findIndex(({ id }) => id == control.id);
    if (i == -1) return;
    lst.push(lst.splice(i, 1)[0]);
  },
  sendBackward({ control, getters }) {
    swap(getters.controls, control, 1);
  },
  sendToBack({ control, getters }) {
    let lst = getters.controls;
    let i = (lst || []).findIndex(({ id }) => id == control.id);
    if (i == -1) return;
    lst.unshift(lst.splice(i, 1)[0]);
  },
  bringForward({ control, getters }) {
    swap(getters.controls, control, 0);
  },
};

export default helperActionsFactory(actions);

import Vue from "vue";
import ImagesService from "@/services/image";
import ImagesLibraryService from "@/services/images-library";
import Panels from "@/assets/dashboard/panels.json";
import { isEqual, merge } from "lodash";
import alignmentActions from "./alignment-actions";
import distributionActions from "./distribution-actions";
import dimensionActions from "./dimension-actions";
import moveActions from "./move-actions";

const UUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

function initialState() {
  return {
    selectedControls: [],
    //entryKey: undefined,
    panel: null,
    imagesLibraries: null,
    images: null,
    screenId: undefined,
    isRotating: false, // tells if a controls is being rotated,
    dataSimulation: false, // Enable/Disable simulated data control reaction,
    propertyEditorEnabled: true
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_SCREEN_ID(state, value) {
      Vue.set(state, "screenId", value);
    },
    SET_PANEL(state, value) {
      Vue.set(state, "panel", value);
      Vue.set(state, "selectedControls", []);
    },
    RESET_PANEL(state) {
      Vue.set(state, "panel", null);
      state.tab = "";
    },
    SET_CONTROLS(state, controls) {
      Vue.set(state.panel.options, "controls", controls);
      // save(state.entryKey, state.panel || null);
    },
    SELECT_CONTROL(state, controlId) {
      state.selectedControls = [];
      let control = state.panel.options.controls.find(
        ({ id }) => id == controlId
      );
      if (control) state.selectedControls.push(control);
    },
    ADD_CONTROL_TO_SELECTION(state, controlId) {
      let control = state.panel.options.controls.find(
        ({ id }) => id == controlId
      );
      if (control) state.selectedControls.push(control);
    },
    REMOVE_CONTROL_FROM_SELECTION(state, controlId) {
      let index = state.selectedControls.findIndex(({ id }) => id == controlId);
      if (index != -1) state.selectedControls.splice(index, 1);
    },
    ADD_CONTROL(state, control) {
      let controls =
        (state.panel && state.panel.options && state.panel.options.controls) ||
        [];
      if (controls.findIndex((i) => i.id == control.id) == -1) {
        if (control.index == -1) {
          delete control.index;
          state.panel.options.controls.unshift(control);
        } else {
          state.panel.options.controls.push(control);
        }
        Vue.set(state, "panel", state.panel);
      }
    },
    CLEAR_SELECTED_CONTROLS(state) {
      Vue.set(state, "selectedControls", []);
    },
    RESET_STATE(state) {
      Vue.set(state, "panel", null);
      Vue.set(state, "selectedControls", []);
      Vue.set(state, "referenceConnectors", []);
    },
    UPDATE_CONTROL_BY_ID(state, { id, control }) {
      let index = state.panel.options.controls.findIndex(
        (control) => control.id == id
      );
      if (index < 0) {
        state.panel.options.controls.push(control);
        index = state.panel.options.controls.length - 1;
      }
      state.panel.options.controls[index] = Object.assign(
        state.panel.options.controls[index],
        control
      );
    },
    UPDATE_CONTROLS(state, { controls }) {
      controls.forEach((control) => {
        let index = state.panel.options.controls.findIndex(
          ({ id }) => id == control.id
        );
        if (index < 0) {
          state.panel.options.controls.push(control);
          index = state.panel.options.controls.length - 1;
        }
        state.panel.options.controls[index] = Object.assign(
          state.panel.options.controls[index],
          control
        );
      });
    },
    REPLACE_SINGLE_UPDATE(state, { controls }) {
      controls.forEach((control) => {
        let index = state.panel.options.controls.findIndex(
          ({ id }) => id == control.id
        );
        state.panel.options.controls.splice(index, 1, control);
      });
    },
    DISMISS(state) {
      // just trigger event
    },
    SET_REFERENCE_CONNECTORS(state, ids) {
      state.referenceConnectors = [...ids];
    },
    DEL_CONTROL(state, controlId) {
      let index = state.panel.options.controls.findIndex(
        (control) => control.id == controlId
      );
      if (index >= 0) {
        let controls = state.panel.options.controls;
        controls.splice(index, 1);
        Vue.set(state.panel.options, "controls", controls);
      }
    },
    TOGGLE_CONTROL(state, controlId) {
      let index = state.panel.options.controls.findIndex(
        (control) => control.id == controlId
      );
      if (index >= 0) {
        let controls = state.panel.options.controls;
        controls[index].enabled = !controls[index].enabled;
        Vue.set(state.panel.options, "controls", controls);
        //save(state.entryKey, state.panel || null);
      }
    },
    DUPLICATE_CONTROL(state, controlId) {
      let index = state.panel.options.controls.findIndex(
        (control) => control.id == controlId
      );
      if (index >= 0) {
        let controls = state.panel.options.controls;
        //let control={...controls[index]};
        let control = JSON.parse(JSON.stringify(controls[index]));
        let name =
          control?.name ||
          control?.title ||
          control?.synopticComponent?.title ||
          control?.synopticComponent?.componentName ||
          "unamed";
        if (name.match(/\(\d+\)/)) {
          let num = parseInt(name.match(/\(\d+\)/)[0].match(/\d+/)[0]) + 1;
          control.name = name.replace(/\(\d+\)/, `(${num})`);
        } else {
          control.name = name + " (1)";
        }
        control.enabled = true;
        control.synopticComponent.clientRect.top += 10;
        control.synopticComponent.clientRect.left += 10;
        control.id = UUID();
        controls.push(control);
        Vue.set(state.panel.options, "controls", controls);
      }
    },
    SET_IMAGES_LIBRARIES(state, imagesLibraries) {
      state.imagesLibraries = imagesLibraries;
    },
    SET_IMAGES(state, images) {
      state.images = images;
    },
    ADD_IMAGES_LIBRARY(state, imagesLibrary) {
      state.imagesLibraries = [...(state.imagesLibraries || []), imagesLibrary];
    },
    ADD_IMAGE(state, image) {
      state.images = [...(state.images || []), image];
    },
    UPDATE_IMAGES_LIBRARY(state, imagesLibrary) {
      let index = state.imagesLibraries.findIndex(
        ({ id }) => id == imagesLibrary.id
      );
      if (index >= 0) state.imagesLibraries.splice(index, 1, imagesLibrary);
    },
    UPDATE_IMAGE(state, image) {
      let index = state.images.findIndex(({ id }) => id == image.id);
      if (index >= 0) state.images.splice(index, 1, image);
    },
    REMOVE_IMAGES_LIBRARY(state, id) {
      let index = state.imagesLibraries.findIndex(
        ({ id: libId }) => libId == id
      );
      if (index >= 0) state.imagesLibraries.splice(index, 1);
    },
    REMOVE_IMAGE(state, id) {
      let index = state.images.findIndex(({ id: imgId }) => imgId == id);
      if (index >= 0) {
        // reset from the synoptic any reference to this message;
        let path = state.images[index].path;
        if (state?.panel?.options) {
          if (state.panel.options?.canvas?.src == path) {
            Vue.set(state.panel.options.canvas, "src", "");
          }
          (state.panel.options.controls || []).forEach((control) => {
            switch (control?.synopticComponent?.componentName || "") {
              case "SynopticStatusIcon":
                // eslint-disable-next-line no-case-declarations
                let stateImage = (
                  control.synopticComponent.stateImages || []
                ).find((item) => item.img == path);
                if (stateImage) {
                  Vue.set(stateImage, "img", "");
                }
                break;
              case "SynopticImage":
                if (control.synopticComponent.src == path) {
                  Vue.set(control.synopticComponent, "src", "");
                }
                break;
            }
          });
        }
        state.images.splice(index, 1);
      }
    },
    MOVE_CONTROL(state, option) {
      if (
        !(option.controlId && ["DOWN", "UP"].indexOf(option.direction) >= 0)
      ) {
        return;
      }
      let controls = state?.panel?.options?.controls || [];
      if (controls.length) {
        let old_index = controls.findIndex(
          (control) => control.id == option.controlId
        );
        if (old_index >= 0) {
          let new_index = old_index + (option.direction == "DOWN" ? -1 : 1);
          if (new_index > controls.length) {
            new_index = controls.length - 1;
          } else if (new_index < 0) {
            new_index = 0;
          }
          controls.splice(new_index, 0, controls.splice(old_index, 1)[0]);
          state.panel.options.controls = controls;
        }
      }
    },
    IS_ROTATING(state, rotating) {
      state.isRotating = rotating;
    },
    SET_PANEL_LOCKED(state, locked) {
      state.panel.locked = locked;
    },
    SET_SELECT_TO_CHANGE(state, selectToChange) {
      Vue.set(state.panel, "selectToChange", selectToChange);
    },
    SET_DATA_SIMULATION(state, value) {
      state.dataSimulation = value;
    },
    SET_PROPERTY_EDITOR_ENABLED(state, value) {
      state.propertyEditorEnabled = value;
    },
    SET_CONTROLS_LOCKER_STATE(state, payload) {
      (payload || []).forEach((entry) => {
        let control = (state?.panel?.options?.controls || []).find(
          (i) => i.id == entry.id
        );
        if (control) Vue.set(control, "locked", entry.state);
      });
    }
  },
  actions: {
    restore(context, config) {
      context.dispatch("removeDraft");
      context.dispatch("setupPanel", config);
    },
    addToSelection(context, controlId) {
      if (
        !(context.state.selectedControls || []).find((i) => i.id == controlId)
      ) {
        context.commit("ADD_CONTROL_TO_SELECTION", controlId);
      }
    },
    removeFromSelection(context, controlId) {
      if (
        (context.state.selectedControls || []).find((i) => i.id == controlId)
      ) {
        context.commit("REMOVE_CONTROL_FROM_SELECTION", controlId);
      }
    },
    setControls(context, controls) {
      if (!controls) return;
      context.commit("CLEAR_SELECTED_CONTROLS");
      context.commit("SET_CONTROLS", JSON.parse(JSON.stringify(controls)));
      context.dispatch("save");
    },
    clearSelectedControls(context) {
      context.commit("CLEAR_SELECTED_CONTROLS");
    },
    create(context, componentName) {
      // TODO: Move it to dashboard module
      let panel = Panels.find((p) => p.template.template == componentName); // get from the library and insert it.
      if (panel) {
        context.commit("SET_PANEL", JSON.parse(JSON.stringify(panel.template)));
        context.dispatch("save");
      }
    },
    clearSelection(context) {
      // IMPORTANT: generic method that will be used by all panels
      context.commit("CLEAR_SELECTED_CONTROLS");
    },
    save(context) {
      return context.dispatch(
        "dashboard/saveDraftPanel",
        {
          screenId: context.state.screenId,
          panel: context.state.panel,
          setAsCurrent: true
        },
        { root: true }
      );
    },
    setupPanel(context, config) {
      if (context.state.panel && context.state.panel.name == config.panel.name) return;
      /*
        KEY=dashboard_id (json id) * panel_name
        since each json has its own id but same panel name - key must be different because of selection
      */
      // todo: move it to dashboard module
      context.commit("SET_PANEL", null);
      context.commit("SET_SCREEN_ID", config.id);
      // let prev = context.rootGetters["dashboard/draftPanel"]({
      //   screenId: config.id,
      //   panelName: config.panel.name
      // });
      // draft panel is recovered earlier in the setup flow
      let panel = config.panel;
      panel.options.controls = panel.options.controls.map((control) => ({
        ...control,
        id: control.id ?? UUID(),
        locked: control.locked ?? false
      }));
      panel.locked = panel.locked ?? false;
      const _r = () => {
        context.commit("RESET_STATE");
        context.commit("SET_PANEL", panel);
        context.dispatch("save");
      };
      if (
        context.state.panel &&
        context.state.panel.name != config.panel.name
      ) {
        context.dispatch("save").then(() => {
          _r();
        });
      } else {
        _r();
      }
    },
    setPanel(context, panel) {
      if (
        context.state.panel &&
        panel &&
        context.state.panel.name == panel.name
      ) {
        context.dispatch("save");
        return;
      }
      context.commit("SET_PANEL", panel);
      context.dispatch("save");
    },
    resetState(context) {
      context.commit("RESET_STATE");
      return context.dispatch("save");
    },
    addControl(context, control) {
      let nControl = {
        ...JSON.parse(JSON.stringify(control)),
        id: UUID()
      };
      context.commit("ADD_CONTROL", nControl);
      context.commit("UPDATE_CONTROL_BY_ID", {
        id: nControl.id,
        control: nControl
      });
      context.dispatch("save");
      context.commit("CLEAR_SELECTED_CONTROLS");
      context.commit("ADD_CONTROL_TO_SELECTION", nControl.id);
      return nControl;
    },
    updateControl(context, { id, control, noMerge, noRecord, forceRecord }) {
      let index = (context.state?.panel?.options?.controls || []).findIndex(
        (control) => control.id == id
      );
      if (index >= 0) {
        // avoid duplicated publish
        let sObject = JSON.stringify(
          context.state.panel.options.controls[index]
        );
        let oObject = JSON.parse(sObject);
        let nObject = noMerge
          ? JSON.parse(JSON.stringify(control))
          : merge(JSON.parse(sObject), JSON.parse(JSON.stringify(control)));
        if (forceRecord || !isEqual(nObject, oObject)) {
          context.commit("UPDATE_CONTROL_BY_ID", {
            id: id,
            control: nObject,
            noRecord
          });
        }
        context.dispatch("setPanel", context.state.panel); // force draft to be saved
      }
    },
    updateControls(
      { state, dispatch, commit },
      { controls, noMerge, noRecord, forceRecord }
    ) {
      let controlsToUpdate = [],
        oldControls = [];
      controls.forEach((control) => {
        let index = (state.panel?.options?.controls || []).findIndex(
          ({ id }) => id == control.id
        );
        if (index >= 0) {
          let oldControl = state.panel.options.controls[index];
          oldControls.push(oldControl);
          let newControl = noMerge
            ? JSON.parse(JSON.stringify(control))
            : merge(JSON.parse(JSON.stringify(oldControl)), control);
          if (forceRecord || !isEqual(newControl, oldControl)) {
            controlsToUpdate.push(newControl);
          }
        }
      });

      commit("REPLACE_SINGLE_UPDATE", { controls: oldControls });
      commit("UPDATE_CONTROLS", { controls: controlsToUpdate, noRecord });
      dispatch("setPanel", state.panel); // force draft to be saved
    },
    selectControl(context, controlId) {
      /* select a single control */
      let control = (context.state?.panel?.options?.controls || []).find(
        (i) => i.id == controlId
      );
      if (control) {
        if (
          !(context.state.selectedControls || []).find((i) => i.id == controlId)
        ) {
          control = JSON.parse(JSON.stringify(control));
          context.commit("CLEAR_SELECTED_CONTROLS");
          context.commit("ADD_CONTROL_TO_SELECTION", controlId);
        }
      }
    },
    selectAll({ commit, getters }) {
      commit("CLEAR_SELECTED_CONTROLS");
      getters.controls.forEach((control) =>
        commit("ADD_CONTROL_TO_SELECTION", control.id)
      );
    },
    delControl(context, controlId) {
      let control = (context.state?.panel?.options?.controls || []).find(
        (i) => i.id == controlId
      );
      if (control) {
        context.commit("DEL_CONTROL", controlId);
        context.commit("REMOVE_CONTROL_FROM_SELECTION", controlId);
        context.dispatch("save");
      }
    },
    toggleControl(context, controlId) {
      //dispatch("save");
      let control = (context.state?.panel?.options?.controls || []).find(
        (i) => i.id == controlId
      );
      if (control) {
        let sObject = JSON.stringify(control);
        control = JSON.parse(sObject);
        context.commit("UPDATE_CONTROL_BY_ID", {
          id: controlId,
          control: control
        });
        context.commit("DISMISS");
        control = JSON.parse(sObject);
        control.enabled = !control.enabled;
        context.commit("UPDATE_CONTROL_BY_ID", {
          id: controlId,
          control: control
        });
        context.dispatch("save");
        // context.commit("TOGGLE_CONTROL", controlId);
      }
    },
    duplicateControl(context, controlId) {
      context.commit("DUPLICATE_CONTROL", controlId);
      // context.commit("CLEAR_SELECTED_CONTROLS");
      let last = (context.state?.panel?.options?.controls?.length || 0) - 1;
      if (last >= 0) {
        let control = context.state.panel.options.controls[last];
        // console.log(controlId+" "+control.id);
        // context.commit("ADD_CONTROL_TO_SELECTION", control.id);
        // context.dispatch("save");
        context.dispatch("selectControl", control.id);
      }
    },
    setControlsLockerState(context, payload) {
      context.commit("SET_CONTROLS_LOCKER_STATE", payload);
    },
    toggleLockControls({ getters, commit }) {
      commit("SET_PANEL_LOCKED", !getters.isPanelLocked);
    },
    toggleSelectToChange({ getters, commit }) {
      commit("SET_SELECT_TO_CHANGE", !getters.selectToChange);
    },
    async fetchImagesLibraries({ commit, rootGetters }) {
      let contract_id = rootGetters["user/contract_id"];
      let service = new ImagesLibraryService();
      let response = await service.fetch({ contract_id });
      if (response) {
        commit("SET_IMAGES_LIBRARIES", response);
      }
    },
    async fetchImages({ commit, rootGetters }) {
      let contract_id = rootGetters["user/contract_id"];
      let service = new ImagesService();
      let response = await service.fetch({ contract_id });
      if (response) {
        commit("SET_IMAGES", response);
      }
    },
    async createImagesLibrary({ commit }, payload) {
      let service = new ImagesLibraryService();
      let response = await service.save(payload);
      if (response) {
        commit("ADD_IMAGES_LIBRARY", response);
      }
      return response;
    },
    async uploadImage({ commit }, payload) {
      let service = new ImagesService();
      let response = await service.save(payload);
      if (response) {
        commit("ADD_IMAGE", response);
      }
      return response;
    },
    async updateImagesLibrary({ commit }, payload) {
      let service = new ImagesLibraryService();
      let response = await service.save(payload);
      if (response) {
        commit("UPDATE_IMAGES_LIBRARY", response);
      }
      return response;
    },
    async updateImage({ commit }, payload) {
      let service = new ImagesService();
      let response = await service.save(payload);
      if (response) {
        commit("UPDATE_IMAGE", response);
      }
      return response;
    },
    async removeImagesLibrary({ commit, state, dispatch }, id) {
      let { etag } =
        state.imagesLibraries.find(({ id: libId }) => id == libId) ?? {};
      if (!etag || !id) throw new Error("Cannot remove given images library");
      let service = new ImagesLibraryService();
      let response = await service.remove({ id, etag });
      commit("REMOVE_IMAGES_LIBRARY", id);
      return response;
    },
    async removeImage({ commit, state }, id) {
      let { etag } = state.images.find(({ id: imgId }) => id == imgId) ?? {};
      if (!etag || !id) throw new Error("Cannot remove given image");
      let service = new ImagesService();
      let response = await service.remove({ id, etag });
      commit("REMOVE_IMAGE", id);
      return response;
    },
    replaceImagePath(context, entry) {
      (context.state?.panel?.options?.controls || []).forEach((control) => {
        if (!control.synopticComponent) return;
        let src = (control?.synopticComponent?.src || "").split("?")[0];
        let payload = null;
        if (src && src === entry.oldVal.split("?")[0]) {
          payload = JSON.parse(JSON.stringify(control));
          payload.synopticComponent.src = entry.newVal;
        }
        else if (!src && (control?.synopticComponent?.stateImages || []).length) {
          (control?.synopticComponent?.stateImages || []).forEach((i, ix) => {
            src = (i.img || "").split("?")[0];
            if (src && src === entry.oldVal.split("?")[0]) {
              payload = payload || JSON.parse(JSON.stringify(control));
              payload.synopticComponent.stateImages[ix].img = entry.newVal;
            }
          });
        }
        if (payload) {
          context.commit("UPDATE_CONTROL_BY_ID", { id: control.id, control: payload });
        }
      });
    },
    removeDraft(context) {
      let key = context.state.entryKey;
      localStorage.removeItem(key);
      context.commit("RESET_PANEL");
      context.dispatch("save");
    },
    setRotating({ commit }, rotating) {
      commit("IS_ROTATING", rotating);
    },
    setPanelProperties(context, payload) {
      if (!context.state.panel || context.state.panel.name !== payload.name) return;
      let changed = false;
      for (var p in payload) {
        if (p != "name" && p in context.state.panel && (!isEqual(context.state.panel[p], payload[p]))) {
          context.state.panel[p] = payload[p];
          changed = true;
        }
      }
      if (!changed) return;
      context.commit("SET_PANEL", context.state.panel);
      context.dispatch("save");
    },
    setDataSimulation(context, value) {
      context.commit("SET_DATA_SIMULATION", value);
    },
    // Control alignment and positioning actions
    ...alignmentActions,
    ...distributionActions,
    ...dimensionActions,
    ...moveActions,
    resetPanel(context) {
      context.commit("RESET_PANEL");
    },
    setPropertyEditorEnabled(context, value) {
      context.commit("SET_PROPERTY_EDITOR_ENABLED", value);
    },
    setDataSimulation(context, value) {
      context.commit("SET_DATA_SIMULATION", value);
    }
  },
  getters: {
    panel(state) {
      return state.panel || null;
    },
    controls(state) {
      return (
        (state.panel && state.panel.options && state.panel.options.controls) ||
        []
      );
    },
    selectedControls(state) {
      return state.selectedControls;
    },
    imagesFrom(state) {
      return (imagesLibraryId) =>
        state.images.filter(
          ({ images_library_id }) => images_library_id == imagesLibraryId
        );
    },
    images(state) {
      return state.images;
    },
    imagesLibraries(state) {
      return state.imagesLibraries;
    },
    isRotating(state) {
      return state.isRotating;
    },
    isPanelLocked(state) {
      return state.panel?.locked ?? false;
    },
    selectToChange(state) {
      return state.panel?.selectToChange ?? false;
    },
    dataSimulation(state) {
      return state.dataSimulation;
    },
    propertyEditorEnabled(state) {
      return state.propertyEditorEnabled;
    }
  }
};

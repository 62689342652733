const trialDaysConst = 30;
const trialDaysLeftNotificationConst = 7;

const alertInfoConst = {
    "NORMAL": 0,
    "WARNING": 1,
    "BLOCKED": 2
};

const contractPlanConst = {
    "FREE": 1,
    "BASIC": 2,
    "PLUS": 3,
    "ENTERPRISE": 4,
    "ENTERPRISE_OEE": 5
}

const contractStateConst = {
    "TEST": 1,
    "RENEWAL": 2,
    "PENDING": 3,
    "NOTCONFIRMED": 4,
    "CONTRACTED": 5,
    "INSUFFICIENT": 6,
    "DEVELOPMENT": 7
};

const sourceValueConst = {
    "ADDRESS": 1,
    "LOCAL": 2
};

const dataHistoryTypeConst = {
    "PERIODIC": 1,
    "CHANGES": 2,
    "PERIODIC_AND_CHANGES": 3,
    "ALL": 4
}

export {
    trialDaysConst,
    trialDaysLeftNotificationConst,
    alertInfoConst,
    contractPlanConst,
    contractStateConst,
    sourceValueConst,
    dataHistoryTypeConst
};
import Vue from "vue";
import VueResource from "vue-resource";
import AuthService from "@/services/auth";
import { parseMessage } from "@/utils";

Vue.use(VueResource);

export default class ImagesLibraryService {
  async fetch(params = {}) {
    let url = "images_libraries/";
    let auth = new AuthService();
    try {
      let response = await Vue.http.get(url, {
        params,
        ...auth.requestOptions()
      });
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async get(id, params = {}) {
    let url = `images_libraries/${id}/`;
    let auth = new AuthService();
    try {
      let response = await Vue.http.get(url, {
        params,
        ...auth.requestOptions()
      });
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async save(payload) {
    let url = `images_libraries/`;
    let auth = new AuthService();
    let request;

    // if id and etag was passed, use patch
    // otherwise use post
    if (payload.id && payload.etag) {
      request = Vue.http.patch(
        url + payload.id + "/",
        payload,
        auth.requestOptions({ "If-Match": payload.etag })
      );
    } else {
      request = Vue.http.post(url, payload, auth.requestOptions());
    }

    // await for request and handle result
    try {
      let response = await request;
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }

  async remove(params, etg) {
    let id, etag;
    if (typeof params == "object") {
      ({ id, etag } = params);
    } else {
      id = params;
      etag = etg;
    }
    let url = `images_libraries/${id}/`;
    let auth = new AuthService();
    try {
      let response = await Vue.http.delete(
        url,
        auth.requestOptions({ "If-Match": etag })
      );
      if (response?.body) {
        return response.body;
      } else {
        return null;
      }
    } catch (e) {
      throw new Error(
        e.message ||
          e.body?.detail ||
          parseMessage(e.body ?? {}) ||
          "Failed to fetch"
      );
    }
  }
}
